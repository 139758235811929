<template>
  <div>
    <div class="mb-1 pt-25 d-flex align-items-center justify-content-between">
      <h6 class="m-0 text-ellipsis">
        {{ $t('pages.crop-types.one.plots-list.name') }}
      </h6>
      <div v-if="has && allowToAdd">
        <TouchButton
          size="xs"
          color="primary"
          @action="goToAdd"
        >
          {{ $t('therms.add') }}
        </TouchButton>
      </div>
    </div>
    <b-list-group
      class="mb-2"
    >
      <template v-if="has">
        <b-list-group-item
          v-for="item in plots"
          :key="item.id"
          class="d-flex justify-content-between align-items-center"
          @action="goToOne(item.id)"
        >
          <span class="text-ellipsis">
            {{ item.name }} {{ (item.abbreviation+'').toLowerCase() !== (item.name+'').toLowerCase() ? '(' + item.abbreviation + ')' : '' }}
          </span>
          <span class="col-2 d-flex justify-content-end m-0 p-0">
            <b-list-group-item-button
              disable
              color="primary"
            >
              <i class="fa-solid fa-angle-right" />
            </b-list-group-item-button>
          </span>
        </b-list-group-item>
      </template>
      <template v-else>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
          @action="goToAdd"
        >
          <span class="text-ellipsis text-black-50">
            {{ $t('pages.crop-types.one.plots-list.no-plot-assign') }}
          </span>

          <TouchButton
            :disable="true"
            size="xs"
          >
            {{ $t('therms.add') }}
          </TouchButton>
        </b-list-group-item>
      </template>
    </b-list-group>
  </div>
</template>

<script>
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import ListGroupItem from '@core/components/list-group/ListGroupItem.vue'
import ListGroupItemButton from '@core/components/list-group/ListGroupItemButton.vue'
import { BListGroup } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { NAME_CROP_TYPE_ONE_PLOT_ADD, NAME_CROP_TYPE_ONE_PLOT_ONE } from '@/router/routes/cropTypes'

export default {
  components: {
    TouchButton,
    BListGroup,
    'b-list-group-item': ListGroupItem,
    'b-list-group-item-button': ListGroupItemButton,
  },
  props: {
    crop: {
      type: Object,
      required: true,
    },
    cropType: {
      type: Object,
      required: true,
    },
    allowToAdd: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // ui

      // data
    }
  },
  computed: {
    ...mapGetters({
      list: 'plots/getList',
    }),
    has() {
      return this.plots !== undefined && this.plots !== null && this.plots.length >= 1
    },
    plots() {
      return this.list.filter(p => this.cropType.plots_id.includes(p.id))
    },
  },
  watch: {},
  methods: {
    goToAdd() {
      this.$router.push({
        name: NAME_CROP_TYPE_ONE_PLOT_ADD,
        params: {
          cropId: this.crop.id,
          cropTypeId: this.cropType.id,
        },
      })
    },
    goToOne(id) {
      this.$router.push({
        name: NAME_CROP_TYPE_ONE_PLOT_ONE,
        params: {
          cropId: this.crop.id,
          cropTypeId: this.cropType.id,
          plotId: id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../../@core/scss/base/components/_include';

</style>
